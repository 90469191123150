/* eslint-disable react/no-unescaped-entities */
import React, { useRef, useEffect, useState } from "react";
import Link from "next/link";
import Image from "next/image";

const Blog = ({ posts }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [slidesPerView, setSlidesPerView] = useState(3);
    const [isTransitioning, setIsTransitioning] = useState(false);

    // Add a local state for extendedData
    const [extendedData, setExtendedData] = useState([]);

    const blogBreakpoints = {
        0: { slidesPerView: 1 },
        576: { slidesPerView: 1 },
        768: { slidesPerView: 2 },
        992: { slidesPerView: 3 },
        1200: { slidesPerView: 3 }
    };

    useEffect(() => {
        const updateSlidesPerView = () => {
            const width = window.innerWidth;
            let newSlidesPerView = 3;
            const breakpointValues = Object.keys(blogBreakpoints).map(Number).sort((a, b) => a - b);
            for (const breakpoint of breakpointValues) {
                if (width >= breakpoint) {
                    newSlidesPerView = blogBreakpoints[breakpoint].slidesPerView;
                }
            }
            setSlidesPerView(newSlidesPerView);
        };
        updateSlidesPerView();
        window.addEventListener("resize", updateSlidesPerView);
        return () => window.removeEventListener("resize", updateSlidesPerView);
    }, []);

    useEffect(() => {
        // Build extendedData on the client
        const dataCopy = [...(posts || [])];
        dataCopy.push(...(posts || []).slice(0, slidesPerView));
        setExtendedData(dataCopy);
    }, [posts, slidesPerView]);

    useEffect(() => {
        // Only run the interval if 'window' is available (client-side)
        if (typeof window === 'undefined') return;
        const interval = setInterval(() => {
            if (!isTransitioning && extendedData.length > 0) nextSlide();
        }, 2000);
        return () => clearInterval(interval);
    }, [isTransitioning, extendedData]);

    const nextSlide = () => {
        setIsTransitioning(true);
        setCurrentIndex((prev) => {
            const next = prev + 1;
            if (next >= (posts || []).length) {
                setTimeout(() => {
                    setIsTransitioning(false);
                    setCurrentIndex(0);
                }, 500);
                return next;
            }
            setTimeout(() => setIsTransitioning(false), 500);
            return next;
        });
    };

    return (
        <section className="blog padding-top padding-bottom banner--style47">
            <div className="container">
                <div className="section-header d-md-flex align-items-center justify-content-between">
                    <div className="section-header__content">
                        <h2 className="mb-15">
                            <span>Instaswap</span> Blog
                        </h2>
                        <p className="mb-0">
                            Dive into the Latest Crypto Trends & Updates
                        </p>
                    </div>
                    <div className="section-header__action">
                        <div className="swiper-nav swiper-nav--style1">
                            <button
                                className="swiper-nav__btn blog__slider-prev"
                                onClick={() => nextSlide()}
                            >
                                <i className="custom-fa-angle-left"></i>
                            </button>
                            <button
                                className="swiper-nav__btn blog__slider-next active"
                                onClick={() => nextSlide()}
                            >
                                <i className="custom-fa-angle-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="blog__wrapper" style={{ overflow: "hidden", position: "relative" }}>
                    <div
                        className="slides-track"
                        style={{
                            display: "flex",
                            transition: isTransitioning ? "transform 0.5s ease" : "none",
                            transform: `translateX(-${(currentIndex * 100) / slidesPerView}%)`
                        }}
                    >
                        {extendedData.length > 0 ? (
                            extendedData.map((post, index) => {
                                let img = "/images/blog/default.png";
                                let alt = "blog image";

                                // Priority: use featured_media_url if available
                                if (post.featured_media_url) {
                                    img = post.featured_media_url;
                                    alt = post.title.rendered || alt;
                                }
                                // Fallback: try using the embedded featured media object (if not forbidden)
                                else if (
                                    post._embedded?.["wp:featuredmedia"] &&
                                    post._embedded["wp:featuredmedia"].length > 0
                                ) {
                                    const media = post._embedded["wp:featuredmedia"][0];
                                    if (!media.code) {
                                        img = media.source_url || img;
                                        alt = media.alt_text || alt;
                                    }
                                }
                                // Fallback: use og_image if available
                                else if (
                                    post.og_image &&
                                    post.og_image.length > 0 &&
                                    post.og_image[0].url
                                ) {
                                    img = post.og_image[0].url;
                                    alt = post.title.rendered || alt;
                                }

                                const title = post.title.rendered;
                                const description = post.excerpt.rendered;
                                const author = post._embedded?.author?.[0]?.name || "Unknown";
                                const authorimg =
                                    post._embedded?.author?.[0]?.avatar_urls?.["48"] ||
                                    "/images/blog/author/default.png";
                                const date = new Date(post.date).toLocaleDateString();
                                const tag =
                                    post._embedded?.["wp:term"]?.[0]?.[0]?.name || "Uncategorized";

                                return (
                                    <div key={index} style={{ minWidth: `${100 / slidesPerView}%` }}>
                                        <div className="blog__item blog__item--style2">
                                            <div className="blog__item-inner">
                                                <div className="blog__thumb">
                                                    <Image src={img} alt={alt} width={500} height={300} />
                                                </div>
                                                <div className="blog__content">
                                                    <div className="blog__meta">
                                                        <span className="blog__meta-tag blog__meta-tag--style1">
                                                            {tag}
                                                        </span>
                                                    </div>
                                                    <h5 className="10">
                                                        <Link href="blog-details">
                                                            <span dangerouslySetInnerHTML={{ __html: title }} />
                                                        </Link>
                                                    </h5>
                                                    <p
                                                        className="mb-15"
                                                        dangerouslySetInnerHTML={{ __html: description }}
                                                    ></p>
                                                    <div className="blog__writer">
                                                        <div className="blog__writer-thumb">
                                                            <Image src={authorimg} alt="writer" width={48} height={48} />
                                                        </div>
                                                        <div className="blog__writer-designation">
                                                            <h6 className="mb-0">{author}</h6>
                                                            <span>{date}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <p>No posts available.</p>
                        )}
                    </div>
                </div>
                <div className="text-center">
                    <Link href="https://blog.instaswap.com" target="_blank" className="trk-btn trk-btn--border trk-btn--primary mt-40">
                        View more
                    </Link>
                </div>
            </div>
            <div className="blog__shape">
                <span className="blog__shape-item blog__shape-item--1">
                    <span></span>
                </span>
            </div>
        </section>
    );
};

export default Blog;
